import { createStore } from 'vuex'

export default createStore({
    state: {
        authToken: null,
        branch: null,
    },
    mutations: {
        
        initializeStore(state){
            if (sessionStorage.getItem('authToken')) {
                state.authToken = JSON.parse(sessionStorage.getItem('authToken'));
            }
            if (localStorage.getItem('branch')) {
                state.branch = JSON.parse(localStorage.getItem('branch'));
            }
        },
        authToken: (state, value) => {
            state.authToken = value
            sessionStorage.authToken = JSON.stringify(state.authToken);
        },
        branch: (state, value) => {
            state.branch = value
            localStorage.branch = JSON.stringify(state.branch);
        },
    },
    actions: {
    },
    modules: {
    }
})
