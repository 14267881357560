import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/register',
    name: 'Register',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/Signup.vue'),
    meta:{
        group: 'guest'
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Signin.vue'),
    meta:{
        group: 'guest'
    }
  },
  {
    path: '/verify-email',
    name: 'VerifyEmail',
    component: () => import('../views/VerifyEmail.vue')
  },
    {path: '/profile', component: () => import('../views/Profile.vue')},
    { path: '/', component: () => import('../views/admin/Dashboard.vue'),  },
    { path: '/dashboard', component: () => import('../views/admin/Dashboard.vue'),  },
    { path: '/branch', component: () => import('../views/admin/Branch.vue') },
    { path: '/cadres', component: () => import('../views/admin/Cadre.vue') },
    { path: '/employees', component: () => import('../views/admin/Employee.vue') },
    { path: '/production', component: () => import('../views/admin/Production.vue') },
    { path: '/mortalities', component: () => import('../views/admin/Mortalities.vue') },
    { path: '/utilities', component: () => import('../views/admin/Utilities.vue') },
    { path: '/assignments', component: () => import('../views/admin/Assignments.vue') },
    { path: '/timesheets', component: () => import('../views/admin/Timesheets.vue') },
    { path: '/products', component: () => import('../views/shop/Products.vue') },
    { path: '/orders', component: () => import('../views/shop/Orders.vue') }
]

const router = createRouter({  
  mode : 'history',
  history: createWebHistory(),
  routes
})

export default router
