<template>
  <div id="main">
      <nav-vue v-if="this.$route.meta.group === 'guest'"/>
      <router-view/>
  </div>
</template>


<script>
import NavVue from './components/Nav/Navigation.vue'
export default {
  name: 'Layout',
  props: {
  },
  beforeCreate() { this.$store.commit('initializeStore') },
  components: {
      NavVue
  }
}
</script>

<style>

</style>
