import '@babel/polyfill'
import 'mutationobserver-shim'
import './assets/vendor/nucleo/css/nucleo.css'
import './assets/vendor/@fortawesome/fontawesome-free/css/all.min.css'
import './assets/css/argon.css';
import { createApp } from 'vue'
import moshaToast from 'mosha-vue-toastify'
import 'mosha-vue-toastify/dist/style.css'
import Layout from './Layout.vue'
import router from './router'
import store from './store'
import VueLoading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

createApp(Layout).use(store).use(router).use(moshaToast).use(VueLoading).mount('#app')
